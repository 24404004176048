<template>
  <v-container class="white lighten-5 min-h-100 px-0" fluid>
    <v-card-title>
      <v-spacer></v-spacer>
      {{ $route.params.id ? 'Edit Company' : 'Add Company' }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-col cols="12" sm="8" xs="12" class="mx-auto">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          :value="
            getRole.id == hradmin
              ? companyJson.cardCompanyName
              : companyJson.name
          "
          @input="
            ($event) => {
              if (getRole.id == hradmin) {
                companyJson.cardCompanyName = $event;
              } else {
                companyJson.name = $event;
              }
            }
          "
          :rules="nameRules"
          label="Name"
          required
        ></v-text-field>

        <v-textarea
          v-model="companyJson.address[0].address"
          :rules="addressRules"
          label="Address"
          required
        ></v-textarea>

        <v-combobox
          v-model="companyJson.companyType"
          :rules="companyTypeRules"
          :items="getCompaniesType"
          item-text="name"
          label="Company Type"
          required
          :readonly="readOnly"
        ></v-combobox>

        <v-text-field
          v-model="companyJson.emailFormat"
          :rules="emailFormatRules"
          label="E-mail Format"
          hint="google"
          required
          :readonly="readOnly"
        ></v-text-field>

        <v-row v-if="getRole.id == superadmin">
          <v-col cols="12" sm="6" md="4">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="companyJson.startDate"
                  label="Start Date"
                  :rules="startDateRules"
                  prepend-icon="fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="companyJson.startDate"
                @input="startDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="companyJson.endDate"
                  label="End Date"
                  :rules="endDateRules"
                  prepend-icon="fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="companyJson.endDate"
                @input="endDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <template v-if="getRole.id == hradmin">
          <div>
            <template  v-for="(email, index) in companyJson.emails " >
              <v-row  class="ma-0">
                <v-text-field
                  :key="'email-' + index"
                  v-model="companyJson.emails[index]"
                  label="Email"
                ></v-text-field>
                <v-btn
                  v-if="index > 0"
                  class="ml-5"
                  @click="
                    {
                      companyJson.emails.splice(index, 1);
                      $forceUpdate();
                    }
                  "
                  >Delete
                </v-btn>
              </v-row>
            </template>
            <v-btn
              @click="
                {
                  companyJson.emails = [...companyJson.emails, ''];
                  $forceUpdate();
                }
              "
              >Add new email
            </v-btn>

            <v-container
              v-for="(pn, index) in companyJson.phoneNumbers"
              :key="'ph-' + index"
            >
              <v-row class="my-3">
                <v-select v-model="pn.type" :items="phoneTypes"></v-select>
                <vue-country-code
                  searchPlaceholderText="Search country"
                  :enableSearchField="true"
                  :enabledCountryCode="true"
                  :enabledFlags="true"
                  :preferredCountries="['in', 'us', 'gb']"
                  style="margin: 0px 20px; height: 55px"
                  @onSelect="
                    ($event) => {
                      companyJson.phoneNumbers[
                        index
                      ].countryCode = `+${$event.dialCode}`;
                      companyJson.phoneNumbers[index].isoCode = $event.iso2;
                    }
                  "
                >
                </vue-country-code>
                <v-text-field
                  v-model="pn.phone"
                  label="Mobile No."
                  style="padding-left: 10px; box-align: center"
                ></v-text-field>
                <v-text-field
                  v-if="pn.type == 'Work' || pn.type == 'Landline'"
                  v-model="pn.ext"
                  label="Office Extension"
                  hint="Eg. 1234"
                  style="padding-left: 10px; box-align: center"
                ></v-text-field>
                <v-btn
                  v-if="index > 0"
                  class="ml-5"
                  @click="
                    {
                      companyJson.phoneNumbers.splice(index, 1);
                    }
                  "
                  >Delete
                </v-btn>
              </v-row>
            </v-container>
            <v-btn
              @click="
                {
                  companyJson.phoneNumbers = [
                    ...companyJson.phoneNumbers,
                    {
                      countryCode: '',
                      phone: '',
                      type: phoneTypes[0],
                      ext: '',
                      isoCode: '',
                      country: '',
                    },
                  ];
                }
              "
              >Add new phone
            </v-btn>

            <div class="mt-10 mb-4 text-h6 font-weight-bold">Business Days</div>
            <v-row class="mb-5 ml-0">
              <v-checkbox
                style="width: 300px"
                v-model="companyJson.businessHours.setCommonTimeForAllDays"
                label="Set Common Time for selected days"
              ></v-checkbox>
              <template
                v-if="companyJson.businessHours.setCommonTimeForAllDays"
              >
                <time-field
                  :label="`Start Time`"
                  :time="companyJson.businessHours.timing.start"
                  :index="-7"
                  :timingtype="`start`"
                  @setTime="setTime"
                ></time-field>
                <div class="text-subtitle-1 text--center mt-4 mx-4">TO</div>
                <time-field
                  :label="`End Time`"
                  :time="companyJson.businessHours.timing.end"
                  :index="-7"
                  :timingtype="`end`"
                  @setTime="setTime"
                ></time-field>
              </template>
            </v-row>

            <v-item-group>
              <v-container
                v-for="(day, ind) in companyJson.businessHours.days"
                :key="'bz' + ind"
              >
                <v-item>
                  <v-row>
                    <v-checkbox
                      style="width: 130px"
                      v-model="day.selected"
                      :label="day.name"
                    ></v-checkbox>
                    <time-field
                      :label="`Start Time`"
                      :time="day.timing.start"
                      :index="ind"
                      :timingtype="`start`"
                      @setTime="setTime"
                    ></time-field>
                    <div class="text-subtitle-1 text--center mt-4 mx-4">TO</div>
                    <time-field
                      :label="`End Time`"
                      :time="day.timing.end"
                      :index="ind"
                      :timingtype="`end`"
                      @setTime="setTime"
                    ></time-field>
                  </v-row>
                </v-item>
              </v-container>
            </v-item-group>

            <v-row class="my-2">
              <div class="text-subtitle-1 font-weight-bold ma-4">
                Break/Lunch Time
              </div>
              <time-field
                :label="`Start Time`"
                :time="companyJson.businessHours.break_lunch_time.start"
                :index="-8"
                :timingtype="`start`"
                @setTime="setTime"
              ></time-field>
              <div class="text-subtitle-1 text--center mt-4 mx-4">TO</div>
              <time-field
                :label="`End Time`"
                :time="companyJson.businessHours.break_lunch_time.end"
                :index="-8"
                :timingtype="`end`"
                @setTime="setTime"
              ></time-field>
            </v-row>

            <v-text-field
              v-model="companyJson.businessHours.notes"
              label="Business Notes"
            ></v-text-field>

            <v-text-field
              v-model="companyJson.website"
              label="Website"
            ></v-text-field>

            <v-combobox
              v-model="companyJson.tags"
              hide-selected
              placeholder="Type your tags here and press enter button"
              label="Business Tags"
              multiple
              persistent-hint
              chips
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  small
                  large
                  color="red"
                  outlined
                >
                  <span class="pr-2" style="color: red">
                    {{ item }}
                  </span>
                  <v-icon x-small color="red" @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
            <p style="margin-bottom: 0px">
              Tag your card with a genre that most suits your business. This
              will help users to locate your business better and make it more
              visible.
            </p>
            <h4>Eg.: Estate agent, travel, doctor, plumber</h4>

            <v-container
              v-for="(sl, index) in companyJson.socialLinks"
              :key="index"
            >
              <v-row class="my-3">
                <h4 class="mt-5 mr-5">{{ sl.type }}</h4>
                <vue-country-code
                  v-if="
                    sl.type == 'WhatsApp' ||
                    sl.type == 'Signal' ||
                    sl.type == 'Telegram'
                  "
                  searchPlaceholderText="Search country"
                  :enableSearchField="true"
                  :enabledCountryCode="true"
                  :enabledFlags="true"
                  :preferredCountries="['in', 'us', 'gb']"
                  style="margin: 0px 20px; height: 55px"
                  @onSelect="
                    ($event) => {
                      companyJson.socialLinks[
                        index
                      ].countryCode = `+${$event.dialCode}`;
                      companyJson.socialLinks[index].isoCode = $event.iso2;
                    }
                  "
                >
                </vue-country-code>
                <v-text-field
                  v-model="sl.link"
                  :label="
                    sl.type == 'WhatsApp' ||
                    sl.type == 'Signal' ||
                    sl.type == 'Telegram'
                      ? 'Mobile No.'
                      : 'Link'
                  "
                  style="padding-left: 10px; box-align: center"
                ></v-text-field>
                <v-btn
                  class="ml-5"
                  @click="
                    {
                      companyJson.socialLinks.splice(index, 1);
                    }
                  "
                  >Delete
                </v-btn>
              </v-row>
            </v-container>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Add new social
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in socialOptions"
                  :key="'so-' + index"
                  @click="onSelectSocial(item)"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div class="my-5">
              Add information and give users more insight into your profile. For
              Example:
              <h5>
                - As a restaurant owner, upload your latest offer on a buffet.
              </h5>
              <h5>
                - As a medical professional, let your patients know about the
                upcoming medical camp.
              </h5>
              <h5>
                - As an individual, upload your resume and reach out to
                prospective employers
              </h5>
            </div>
            <v-container
              v-for="(mi, index) in companyJson.moreInfo"
              :key="'moreInfo-' + index"
            >
              <v-row class="ma-0">
                <v-textarea
                  v-model="companyJson.moreInfo[index].title"
                  label="Title"
                  hint="A title is a placeholder for your users to see, hence keep it relevant."
                  persistent-hint
                  required
                  rows="2"
                ></v-textarea>
                <div v-if="mi.file_name && mi.file_url">
                  <h4>{{ mi.file_name }}</h4>
                  <a :href="mi.file_url" target="_blank">show</a>
                </div>
                <input
                  v-else
                  class="mb-2"
                  type="file"
                  multiple
                  :accept="moreInfoExts.join(',')"
                  max-file-size="20000"
                  @change="
                    (event) => inputClickFileSelector(event, 'moreInfo', index)
                  "
                />
                <v-btn class="ml-5" @click="deleteMoreInfo(index)">
                  Delete
                </v-btn>
              </v-row>
            </v-container>
            <v-btn
              @click="
                {
                  companyJson.moreInfo = [
                    ...companyJson.moreInfo,
                    {
                      created_datetime: null,
                      file_name: '',
                      file_url: '',
                      title: '',
                    },
                  ];
                  moreInfoFiles = [...moreInfoFiles, null];
                  $forceUpdate();
                }
              "
              >Add More Info
            </v-btn>

            <v-checkbox
              style="margin-top: 30px; margin-left: 10px"
              v-model="companyJson.applyElementPosition"
              label="Apply Company Card Element Position in Employees Card"
            ></v-checkbox>

            <card-draggable
              class="my-10"
              :cardData="companyJson"
              :backgroundCardImage="backgroundCardImage"
              :logoCardImage="logoCardImage"
              :customFont="customFont"
              @inputClickFileSelector="inputClickFileSelector"
            />

            <!-- <v-text-field
              v-model="companyJson.commonPassword"
              label="Common Password"
              style="padding-left: 10px; align: center"
            ></v-text-field> -->
          </div>
        </template>

        <v-progress-circular
          v-if="loading"
          class="my-10"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-row v-else>
          <v-btn
            :disabled="!valid"
            color="primary"
            class="mr-4 mt-10"
            @click="
              {
                if (
                  getRole &&
                  getRole.id == hradmin &&
                  $route.params.id &&
                  $route.path == `/edit-company/${$route.params.id}`
                ) {
                  confirmDialog = true;
                } else {
                  validateForm(false);
                }
              }
            "
          >
            Submit
          </v-btn>
        </v-row>
      </v-form>
    </v-col>

    <v-dialog v-model="confirmDialog" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Important Notes
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="confirmDialog = false">
            Cancel
          </v-btn>
        </v-card-title>

        <v-card-text class="my-5">
          <h3>
            If you want like company template card changes for your employee
            cards
          </h3>
          <v-checkbox
            v-if="!companyJson.applyElementPosition"
            v-model="companyJson.applyElementPosition"
            label="Apply Company Card Element Position in Employees Card"
          ></v-checkbox>
          <h4>
            then click "Save & Go To All Card Template Preview Page" button
          </h4>
          <h3 class="mt-3">Otherwise click Save Button</h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="success"
            @click="validateForm(false)"
          >
            Save
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="
              getRole &&
              getRole.id == hradmin &&
              $route.params.id &&
              $route.path == `/edit-company/${$route.params.id}`
            "
            :disabled="!valid"
            color="primary"
            @click="validateForm(true)"
          >
            Save & Go To All Card Template Preview Page
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/storage';
import TimeField from '../components/TimeField.vue';
import CardDraggable from '../components/CardDraggable.vue';

export default {
  name: 'AddCompany',
  components: { TimeField, CardDraggable },
  data: () => ({
    valid: true,
    nameRules: [(v) => !!v || 'Name is required'],
    addressRules: [(v) => !!v || 'Address is required'],
    companyTypeRules: [(v) => !!v || 'Company Type is required'],
    startDateMenu: false,
    startDateRules: [(v) => !!v || 'Start Date is required'],
    endDateMenu: false,
    endDateRules: [(v) => !!v || 'End Date is required'],
    emailFormatRules: [(v) => !!v || 'E-mail Format is required'],
    backgroundCardImage: null,
    logoCardImage: null,
    customFont: null,
    readOnly: false,
    selectSocial: null,
    loading: false,
    moreInfoExts: [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      '.pdf',
      '.txt',
      'audio/mp3',
      'audio/ogg',
      'video/m4a',
    ],
    moreInfoFiles: [],
    deleteFilesUrl: [],
    confirmDialog: false,
    phoneTypes: [
      'Mobile',
      'Landline',
      'Work',
      'School',
      'iPhone',
      'Apple Watch',
      'Main',
      'Home Fax',
      'Work Fax',
      'Pager',
      'Other',
    ],
    socialOptions: [
      { id: 1, icon: '', name: 'WhatsApp' },
      { id: 2, icon: '', name: 'Instagram' },
      { id: 3, icon: '', name: 'LinkedIn' },
      { id: 4, icon: '', name: 'Twitter' },
      { id: 5, icon: '', name: 'Facebook' },
      { id: 6, icon: '', name: 'Signal' },
      { id: 7, icon: '', name: 'Telegram' },
    ],
    companyJson: {
      companyType: null,
      startDate: '',
      name: '',
      endDate: '',
      emailFormat: '',
      address: [
        {
          address: '',
          latlon: [0.0, 0.0],
        },
      ],
      emails: [],
      tags: [],
      moreInfo: [],
      applyElementPosition: true,
      phoneNumbers: [],
      website: '',
      businessHours: {
        days: [],
        timing: {
          start: {
            hour: 0,
            minute: 0,
          },
          end: {
            hour: 0,
            minute: 0,
          },
        },
        setCommonTimeForAllDays: true,
        break_lunch_time: {
          start: {
            hour: 0,
            minute: 0,
          },
          end: {
            hour: 0,
            minute: 0,
          },
        },
        notes: '',
      },
      socialLinks: [],
      design: {
        bgColor: 4294198070,
        logoUrl: '',
        bgUrl: '',
        enableShadow: true,
        showOverlay: '',
        overlayIntensity: 10.0,
        fontUrl: '',
        fontName: '',
        //unused
        textColor: 0,
        nameFontSize: 0.0,
        bodyFontSize: 0.0,
        contactFontSize: 0.0,
        layoutOption: 1,
        shadowData: {
          blur: 0.0,
          offset: { x: -2, y: 0 },
        },
      },
      elementDesigns: {},
      cardCompanyName: '',
      commonPassword: 'visicardia@123',
    },
  }),
  watch: {
    getRole(r) {
      if (r?.id == null || r?.id != this.$store.superadmin) {
        this.$router.replace('/');
      }
    },
  },
  mounted() {
    
    if (
      this.getRole?.id == this.superadmin ||
      (this.getRole?.id == this.hradmin &&
        this.$route.params.id &&
        this.$route.path == `/edit-company/${this.$route.params.id}`)
    ) {
      this.setCompanyType();
      this.readOnly = this.getRole.id == this.superadmin ? false : true;
    } else {
      this.$router.push('/');
      return;
    }
  },
  async created() {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    this.startTimeMenus = days.map((d) => false);
    this.endTimeMenus = days.map((d) => false);
    this.companyJson.businessHours.days = days.map((day) => {
      return {
        name: day,
        selected: false,
        timing: {
          start: {
            hour: 9,
            minute: 0,
          },
          end: {
            hour: 18,
            minute: 0,
          },
        },
      };
    });
    const topBase = 5.0;
    this.companyJson.elementDesigns = {
      logoUrl: {
        color: null,
        fontSize: 25,
        fontFamily: 'Nunito',
        bold: null,
        left: null,
        top: topBase,
        right: 0,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      name: {
        color: 4278190080,
        fontSize: 20,
        fontFamily: 'Nunito',
        bold: true,
        left: 0,
        top: topBase,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      phoneNumbers: {
        color: 4278190080,
        fontSize: 12,
        fontFamily: 'Nunito',
        bold: false,
        left: 0,
        top: topBase + 10,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      emails: {
        color: 4278190080,
        fontSize: 12,
        fontFamily: 'Nunito',
        bold: false,
        left: 0,
        top: topBase + 17,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      designation: {
        color: 4278190080,
        fontSize: 12,
        fontFamily: 'Nunito',
        bold: false,
        left: 0,
        top: topBase + 30,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      qualification: {
        color: 4278190080,
        fontSize: 10,
        fontFamily: 'Nunito',
        bold: false,
        left: 0,
        top: topBase + 60,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      company: {
        color: 4278190080,
        fontSize: 12,
        fontFamily: 'Nunito',
        bold: false,
        left: 0,
        top: topBase + 65,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
      address: {
        color: 4278190080,
        fontSize: 10,
        fontFamily: 'Nunito',
        bold: false,
        left: 0,
        top: topBase + 75,
        right: null,
        bottom: null,
        textAlignment: 1,
        fullWidth: true,
        width: null,
      },
    };
    this.companyJson.phoneNumbers = [
      {
        countryCode: '',
        phone: '',
        type: this.phoneTypes[0],
        ext: '',
        isoCode: '',
        country: '',
      },
    ];
    this.companyJson.emails = [''];
    this.selectSocial = this.socialOptions[0];
    if (this.$route.params.id) {
      const list = this.companies.filter((e) => e.id == this.$route.params.id);
      let company;
      if (list.length > 0) {
        company = list[0];
      } else {
        company = await this.fetchCompany(this.$route.params.id);
      }
      if (company) {
        this.companyJson = company;
        const phoneNumbers = this.companyJson.phoneNumbers
          ? this.companyJson.phoneNumbers.map((pn) => {
              pn.phone = pn.phone.replace(`${pn.countryCode}`, '');
              return pn;
            })
          : [];
        this.companyJson.cardCompanyName =
          company.cardCompanyName || company.name;
        this.companyJson.phoneNumbers = phoneNumbers;
        this.companyJson.emails = company.emails || [''];
        this.companyJson.tags = company.tags || [];
        this.companyJson.moreInfo = company.moreInfo || [];
        this.companyJson.applyElementPosition =
          company.applyElementPosition || false;

        if (this.companyJson.moreInfo) {
          this.moreInfoFiles = this.companyJson.moreInfo.map((e) => null);
        }

        this.applyCustomFont(null, {
          name: this.companyJson.design?.fontName || '',
          url: this.companyJson.design?.fontUrl || '',
        });
      }
    }
    await this.fetchCompaniesType({ self: this });
    this.setCompanyType();
  },
  computed: {
    ...mapGetters(['getRole', 'getCompaniesType']),
    ...mapState({
      companies: (state) => state.companies,
      hradmin: (state) => state.hradmin,
      superadmin: (state) => state.superadmin,
    }),
  },
  methods: {
    ...mapActions(['addCompany', 'fetchCompaniesType', 'fetchCompany']),
    ...mapMutations(['setSelectedNode']),
    showTime(time) {
      var hour = time.hour > 12 ? time.hour - 12 : time.hour;
      hour = hour < 10 ? `0${hour}` : hour;
      const minute = time.minute < 10 ? `0${time.minute}` : time.minute;
      const ampm = time.hour > 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    setTime(event, ind, type, needle) {
      const cj = { ...this.companyJson };
      if (ind == -7) {
        cj.businessHours.timing[`${type}`][`${needle}`] = event;
      } else if (ind == -8) {
        cj.businessHours.break_lunch_time[`${type}`][`${needle}`] = event;
      } else {
        cj.businessHours.days[ind].timing[`${type}`][`${needle}`] = event;
      }
      this.companyJson = cj;
    },
    onSelectSocial(event) {
      this.companyJson.socialLinks = [
        ...this.companyJson.socialLinks,
        {
          type: event.name,
          link: '',
          countryCode: '',
          isoCode: '',
        },
      ];
    },
    setCompanyType() {
      if (this.getCompaniesType.length > 0 && this.$route.params.id) {
        const filter = this.getCompaniesType.filter(
          (e) => e.id == this.companyJson?.companyType?.id
        );
        if (filter.length > 0) {
          this.companyJson.companyType = filter[0];
        }
      }
    },
    inputClickFileSelector(event, type, index) {
      console.log(event);
      const files = event?.target?.files;
      const data = files?.length
        ? {
            file: files.item(0),
            previewUrl: URL.createObjectURL(files.item(0)),
            uploadValue: 0,
          }
        : null;
      if (type == 'bg') {
        if (!this.backgroundCardImage && !data) {
          this.deleteFilesUrl.push(this.companyJson.design.bgUrl);
          this.companyJson.design.bgUrl = '';
        }

        this.backgroundCardImage = data;
        this.companyJson.design.overlayIntensity = 0.0;
      } else if (type == 'logo') {
        if (!this.logoCardImage && !data) {
          this.deleteFilesUrl.push(this.companyJson.design.logoUrl);
          this.companyJson.design.logoUrl = '';
        }

        this.logoCardImage = data;
      } else if (type == 'font') {
        if (!this.customFont && !data) {
          this.deleteFilesUrl.push(this.companyJson.design.fontUrl);
          this.companyJson.design.fontUrl = '';
          this.companyJson.design.fontName = '';
        }

        console.log(data);
        this.customFont = data;
        this.applyCustomFont(data, null);
      } else if (type == 'moreInfo') {
        this.moreInfoFiles[index] = data;
        this.$forceUpdate();
      }
      console.log(files);
      // if (event) event.target.value = null;
    },
    applyCustomFont(data, urlData) {
      // upload
      // response of font path
      // create style tag
      let fileName = '';
      let url = '';
      if (data) {
        fileName = data.file.name;
        url = data.previewUrl;
      } else if (urlData) {
        fileName = urlData?.name || 'Nunito';
        url = urlData.url;
      } else {
        fileName = 'Nunito';
      }

      console.log(fileName, url);

      const customFontEl = document.getElementById('custom-font');
      if (customFontEl) {
        // truthy: non-empty string, true, {}, [], >0
        // false: 0, null, undefine, ''
        customFontEl.innerHTML = `
              @font-face {
              font-family: '${fileName}';
              src: url(${url});
            }
            `;
      } else {
        const el = document.createElement('style');
        el.id = 'custom-font';
        el.innerHTML = `
              @font-face {
                font-family: '${fileName}';
                src: url(${url});
              }
              `;
        document.head.appendChild(el);
      }
      if (document.getElementById('card-screenshot'))
        document.getElementById(
          'card-screenshot'
        ).style.fontFamily = `'${fileName}'`;
    },
    async deleteMoreInfo(index) {
      const mi = this.companyJson.moreInfo[index];
      if (mi.file_url && mi.created_datetime) {
        this.deleteFilesUrl.push(mi.file_url);
      }
      this.companyJson.moreInfo.splice(index, 1);
      this.moreInfoFiles.splice(index, 1);
      this.$forceUpdate();
    },
    async validateForm(goToAllCardsTemplate) {
      const validate = this.$refs.form.validate();
      console.log(this.companyJson);
      this.confirmDialog = false;
      this.setSelectedNode(null);
      if (validate) {
        this.loading = true;
        const phoneNumbers = this.companyJson.phoneNumbers.map((pn) => {
          pn.phone = `${pn.countryCode}${pn.phone}`;
          return pn;
        });
        this.companyJson.phoneNumbers = phoneNumbers;
        this.companyJson.cardCompanyName =
          this.companyJson.cardCompanyName || this.companyJson.name;

        const companyId = await this.addCompany({
          company: this.companyJson,
          id: this.$route.params.id,
        });
        await this.onUpload(companyId, this.companyJson);
        this.loading = false;
        if (goToAllCardsTemplate) {
          this.$router.push('/all-cards-template');
        } else {
          this.$router.go(-1);
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    onUpload(companyId, company) {
      if (!companyId) return;
      return new Promise(async (resolve) => {
        const folder = `company/${companyId}`;

        Promise.all(
          this.deleteFilesUrl.map(async (file_url) => {
            if (file_url) {
              try {
                const httpsReference = firebase.storage().refFromURL(file_url);
                await firebase
                  .storage()
                  .ref(`${folder}/${httpsReference.name}`)
                  .delete();
                return true;
              } catch (e) {
                console.log(e);
                return false;
              }
            }
            return false;
          })
        );

        if (this.backgroundCardImage) {
          const bgfileName = `visicardia-background-${companyId}.png`;

          const storageRef = firebase
            .storage()
            .ref(`${folder}/${bgfileName}`)
            .put(this.backgroundCardImage.file);

          await storageRef.then(
            async (snapshot) => {
              switch (snapshot.state) {
                case firebase.storage.TaskState.SUCCESS:
                  this.backgroundCardImage.uploadValue = 100;
                  const url = await snapshot.ref.getDownloadURL();
                  if (url) {
                    company.design.bgUrl = url;
                  }
                  break;
                default:
                  this.backgroundCardImage.uploadValue =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              }
            },
            (error) => {
              console.log(error.message);
            }
          );
        }

        if (this.logoCardImage) {
          const logofileName = `visicardia-logo-${companyId}.png`;

          const storageRef2 = firebase
            .storage()
            .ref(`${folder}/${logofileName}`)
            .put(this.logoCardImage.file);

          await storageRef2.then(
            async (snapshot) => {
              switch (snapshot.state) {
                case firebase.storage.TaskState.SUCCESS:
                  this.logoCardImage.uploadValue = 100;
                  const url = await snapshot.ref.getDownloadURL();
                  if (url) {
                    company.design.logoUrl = url;
                  }
                  break;
                default:
                  this.logoCardImage.uploadValue =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              }
            },
            (error) => {
              console.log(error.message);
            }
          );
        }

        if (this.customFont) {
          const fontfileName = `visicardia-font-${companyId}.ttf`;

          const storageRef3 = firebase
            .storage()
            .ref(`${folder}/${fontfileName}`)
            .put(this.customFont.file);

          await storageRef3.then(
            async (snapshot) => {
              switch (snapshot.state) {
                case firebase.storage.TaskState.SUCCESS:
                  this.customFont.uploadValue = 100;
                  const url = await snapshot.ref.getDownloadURL();
                  if (url) {
                    company.design.fontUrl = url;
                    company.design.fontName = this.customFont.file.name;
                  }
                  break;
                default:
                  this.customFont.uploadValue =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              }
            },
            (error) => {
              console.log(error.message);
            }
          );
        }

        await this.addCompany({
          company,
          id: companyId,
        });

        const promises = this.moreInfoFiles.map(async (fileJson, index) => {
          if (fileJson) {
            const currentDateTime = new Date().getTime();

            const name = fileJson.file.name;
            const splitFileName = name.split('.');
            const ext = splitFileName[splitFileName.length - 1];
            const fileName = `moreinfo-${currentDateTime}-${companyId}.${ext}`;

            const storageRef3 = firebase
              .storage()
              .ref(`${folder}/${fileName}`)
              .put(fileJson.file);

            await storageRef3.then(
              async (snapshot) => {
                switch (snapshot.state) {
                  case firebase.storage.TaskState.SUCCESS:
                    fileJson.uploadValue = 100;
                    const url = await snapshot.ref.getDownloadURL();
                    if (url) {
                      company.moreInfo[index].file_url = url;
                      company.moreInfo[index].file_name = name;
                      company.moreInfo[index].created_datetime =
                        currentDateTime;
                    }
                    break;
                  default:
                    fileJson.uploadValue =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                }
              },
              (error) => {
                console.log(error.message);
              }
            );
          }
          return true;
        });

        await Promise.all(promises);

        await this.addCompany({
          company,
          id: companyId,
        });

        resolve(true);
      });
    },
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.v-chip.v-size--large {
  height: 40px;
}
</style>
