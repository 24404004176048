<template>
  <div>
    <v-menu
      v-model="menuOpen"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="showTime(time)"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          style="padding-left: 10px; max-width: 100px"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menuOpen"
        format="24hr"
        :value="`${time.hour}:${time.minute}`"
        full-width
        @click:hour="
          ($event) => $emit('setTime', $event, index, timingtype, 'hour')
        "
        @click:minute="
          ($event) => $emit('setTime', $event, index, timingtype, 'minute')
        "
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'TimeField',
  data() {
    return {
      menuOpen: false,
    };
  },
  props: {
    label: { type: String, default: 'Time' },
    time: { type: Object },
    index: { type: Number },
    timingtype: { type: String },
  },
  methods: {
    showTime(time) {
      var hour = time.hour > 12 ? time.hour - 12 : time.hour;
      hour = hour < 10 ? `0${hour}` : hour;
      const minute = time.minute < 10 ? `0${time.minute}` : time.minute;
      const ampm = time.hour > 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
  },
};
</script>
